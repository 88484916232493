// IMPORTS
@import (once) "/node_modules/nouislider/dist/nouislider.css";

@import (reference) "../settings.less";

// SIDEBAR
.sidebar {
    display: flex;
    flex-direction: column;
    gap: 24px;
}

// TREE
@media (min-width: 1280px) {
    .cms-rwd,
    .tree-header {
        display: none;
    }

    #tree {

        > nav {
            background-color: @light-bg;
            padding: 11px 0;
            border-radius: var(--borderRadius);
            border: 1px solid @border-color;
        }

        li {
            border-bottom: 1px solid @border-color;
            position: relative;

            &:last-child {
                border-bottom: 0;
            }

            &.downarrow {

                > a {
                    border-bottom: 1px solid @border-color;
                }

                > button:after {
                    transform: scaleY(-1);
                }

                > ul {
                    display: block;
                }
            }
        
            ul {
                display: none;
            }
        }

        li.t0 {

            > a {
                display: block;
                color: @header-color;
                font-weight: 600;
                padding: 14px 0;
                padding-left: 30px;
                padding-right: 55px;
            }
        }

        a {
            position: relative;

            &:hover {
                text-decoration: underline;
            }
        }

        li:not(.t0) {

            a {
                color: @header-color;
                padding: 8px 0;
                padding-right: 55px;
                display: block;
            }
        }

        li.t1 > a {
            padding-left: 44px;
        }

        li.t2 > a {
            padding-left: 52px;
        }

        li.t3 > a {
            padding-left: 60px;
        }

        li.selected > a {
            color: @main-color;
        }

        button {
            position: absolute;
            top: 0;
            left: 0;
            width: 30px;
            height: 49px;
            font-size: 9px;
            display: flex;
            justify-content: center;
            align-items: center;
            color: @header-color;

            &:after {
                content: '\e800';
                font-family: icomoon;
            }
        }

        small {
            position: absolute;
            top: 0;
            right: 10px;
            height: 100%;
            display: flex;
            align-items: center;
            text-align: center;
            font-size: 12px;
            font-weight: normal;
            color: @font-color;
        }
    }
}

// LISTING WRAPPER
.listing-wrapper {
    .grid();
    grid-template-columns: minmax(0, 1fr);
    gap: 30px;
}

// CATEGORY HEADER & DESCRIPTION
.category-header-wrapper {
    padding-bottom: 30px;
    border-bottom: 1px solid @border-color;

    &:not(:has(> *)) {
        display: none;
    }
}

.category-headline {
    clear: none;
    font-size: 20px;
    margin-bottom: 7px;
}

.category-image {
    float: left;
    width: 140px;
    height: 140px;
    margin-right: 42px;
    display: flex;
    justify-content: center;
    align-items: center;

    img {
        max-width: 100%;
        max-height: 100%;
        align-self: center;
        width: auto;
        height: auto;
    }
}

.category-description {
    color: @font-color;
}

// NAVIGATION
.navigation-wrapper {
    display: none;
    flex-wrap: wrap;
    gap: 20px;
    border-radius: 4px; // for buttons inherit

    &:has(#pills-container .single-pill),
    &:has(.dropdown-picker) {
        display: flex;
    }
}

.listing-wrapper:has(.products-list:empty) {

    .navigation-wrapper,
    .dropdown-picker {
        display: none;
    }
}

@media(min-width: 1280px) {
    .inner-wrapper [data-dropdown-type="sort"] {
        margin-left: auto;
    }
}

#pills-container {

    &:empty {
        display: none;
    }

    &:not(:empty) {
        display: flex;
        align-items: flex-end;
        flex-wrap: wrap;
        width: 100%;
        gap: 16px 20px;
    }
}

.listing-button {
    z-index: 10;
    border-radius: inherit;
    position: relative;
    text-align: left;
    border: 1px solid @border-color;
    color: @font-color;
    min-width: 258px;
    height: 48px;
    padding: 0 48px 0 16px;
    margin-top: 7px;
    white-space: nowrap;

    &:before {
        position: absolute;
        top: -12px;
        left: 4px;
        background-color: white;
        padding: 2px 8px;
        color: @header-color;
        font-size: 12px;
        font-weight: 600;
        z-index: 11;
        pointer-events: none;
    }

    &:after {
        position: absolute;
        right: 16px;
        top: 50%;
        transform: translateY(-50%);
        .small-icon();
        color: @header-color;
        content: '\e800';
        font-size: 10px;
    }

    &:hover {
        border-color: @header-color;
    }

    &.filter-trigger {
        display: none;
        align-items: center;

        &:before {
            content: 'Filtrowanie';
        }

        &:after {
            content: "\e912";
            font-size: 24px;
            right: 10px;
        }
    }

    &.page-size {
        min-width: 100px;

        &:before {
            content: 'Ilość na stronie';
        }
    }

    &.sorting {

        &:before {
            content: 'Sortowanie';
        }
    }
}

.dropdown-picker {
    position: relative;
    display: flex;
    z-index: 10;
    border-radius: inherit;

    &.show-dropdown,
    &.show-dropdown-rwd {

        .dropdown-button:after {
            transform: scaleY(-1);
            top: calc(50% - 4px);
        }

        .dropdown-listing {
            .appear();
        }
    }
}

.dropdown-listing {
    opacity: 0;
    visibility: hidden;
    position: absolute;
    top: 100%;
    left: 0;
    background-color: white;
    border: 1px solid @border-color;
    border-top: 0;
    .shadow();
    min-width: 100%;
    padding: 5px 0;
    border-radius: inherit;
    .setup-appear();

    input {
        display: none;

        &:checked + label {
            font-weight: 700;
            color: @header-color;
            text-decoration: none !important
        }
    }

    span {
        display: block;
    }

    label {
        display: block;
        padding: 5px 16px;
        user-select: none;
        cursor: pointer;

        &:hover {
            text-decoration: underline;
        }
    }
}

// IE FALLBACK
@media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
    .sorter-button:before {
        display: none;
    }
}

//// FILTER (ALL 3 TYPES)
#filter {
    --fontSize: 14px;
    --lineHeight: 1.5;
    --liPadding: 4px;
    --shownBeforeOverflow: 6; // if changed also change in filtering.ascx

    input[type="checkbox"] {
        display: none;

        &:checked + label:after {
            transform: scale(1);
        }
    }
}

.gs-filtr-container { // avoids rwd dropdown picker's labels etc

    strong {
        display: flex;
        flex-direction: column;
        color: @header-color;
        padding-block: 8px;
        padding-right: 20px;
        font-weight: 600;
        font-size: 14px;
        position: relative;

        &:first-letter {
            text-transform: uppercase;
        }
    }

    li {
        display: block;
    }

    label,
    i {
        font-size: var(--fontSize);
        line-height: var(--lineHeight);
        display: block;
        position: relative;
        user-select: none;
        color: @font-color;
        font-style: normal;
    }

    label {
        cursor: pointer;
        padding-block: var(--liPadding);
        padding-left: 29px;

        &.hide {
            display: none !important;
        }

        &:before,
        &:after {
            position: absolute;
            top: 6px;
            left: 0;
            width: 18px;
            height: 18px;
            color: @header-color;
            display: flex;
            justify-content: center;
            align-items: center;
        }

        &:before {
            content: '';
            background-color: white;
            border: 1px solid @border-color;
        }

        &:after {
            content: '\e801';
            font-family: icomoon;
            transform: scale(0);
            transition: transform 0.1s ease;
        }
    }
}

.close-filter,
.close-filter-bottom {
    display: none;
}

#price-filter-handles { //line
    height: 4px;
    margin-block: 18px;
    margin-inline: 3px 17px;
    background-color: @border-color;
    border: 0;
    border-radius: 0;
}

.price-filtering .noUi-horizontal .noUi-handle {
    box-shadow: none;
    cursor: grab;
    background-color: @sec-color;
    border-radius: 4px;
    border: 0;
    width: 16px;
    height: 16px;
    top: calc(50% + 2px);
    transform: translateY(-50%);


    &.noUi-active {
        cursor: grabbing;
        background: darken(@sec-color, 10%);
    }

    &:before,
    &:after {
        display: none;
    }
}

#price-filter-amounts {
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 8px;

    label {
        padding: 0 !important;

        &:before,
        &:after {
            display: none !important;
        }
    }

    input {
        height: 36px;
        border: 1px solid @border-color;
        background: transparent;
        text-align: center;
        padding: 0;
        width: 68px;
        flex-grow: 1;
    }
}

// STATIC & SLIDING FILTER
@media (min-width: 1280px) {
    #filter {

        .price-filtering > strong {
            padding: 0;
        }

        strong > span {
            display: none;
        }

        label:hover {
            text-decoration: underline;
        }
    }

    .gs-filtr-container {

        > input {
            font-size: 13px;
            height: 30px;
            margin-bottom: 11px;
            padding: 0 10px;
            margin-right: 8px;
        }

        &.no-filters-found {

            &:after {
                content: 'brak filtrów...';
                color: @error-color;
                display: block;
            }
        }

        &:has(> input) { // scroll activated

            > div {
                position: relative;
            }

            li {
                padding-right: 10px;
            }

            ul {
                max-height: calc((var(--fontSize) * var(--lineHeight) + var(--liPadding) * 2) * var(--shownBeforeOverflow));
                overflow-y: auto;
                .scrollbar();

                &:before,
                &:after {
                    content: '';
                    position: absolute;
                    height: 26px;
                    left: 0;
                    width: calc(100% - 12px);
                    z-index: 1;
                    pointer-events: none;
                }

                &:before {
                    background: linear-gradient(0deg, rgba(0,0,0,0) 0%, rgba(255,255,255,1) 100%);
                    top: 0;
                }

                &:after {
                    background: linear-gradient(180deg, rgba(255,255,255,0) 0%, rgba(255,255,255,1) 100%);
                    bottom: 0;
                }

                li:first-child,
                li:last-of-type {
                    position: relative;
                    z-index: 2;
                }
            }
        }
    }

    // STATIC FILTER ONLY
    body:not([data-canvas-filter]) {

        .filter-trigger {
            display: none;
        }
    }

    .sidebar #filter {
        border-radius: var(--borderRadius);
        overflow: hidden;
        display: none;

        &:has(.gs-filtr-container) {
            display: block;
        }

        > h3 {
            display: block;
            color: white;
            background-color: @sec-color;
            font-size: 16px;
            font-weight: 600;
            padding: 14px 23px 13px;
        }

        .gs-filtr-container {
            border-bottom: 1px solid @border-color;
            border-left: 4px solid @border-color;
            border-right: 4px solid @border-color;
            padding: 24px 17px;

            &:last-of-type {
                border-bottom: 4px solid @border-color;
            }
        }
    }

    // SLIDING FILTER ONLY
    body[data-canvas-filter] {

        &:has(#filter .gs-filtr-container) {

            .navigation-wrapper {
                display: flex;
            }

            .filter-trigger {
                display: flex;
            }
        }
    }

    #container {

        .inner-wrapper {

            &:before {
                content: '';
                position: fixed;
                top: 0;
                left: 0;
                width: 100%;
                height: 100%;
                background-color: rgba(0,0,0,.25);
                z-index: 10000;
                .setup-appear();
                transition-duration: 0.3s;
                transform: none;
            }
        }

        > #filter {
            position: fixed;
            top: 0;
            z-index: 10000;
            background-color: white;
            width: 290px;
            height: 100vh;
            overflow-y: auto;
            padding: 8px 16px;
            transform: translateX(-100% - 10px);
            transition: transform 0.3s cubic-bezier(0.4, 0, 0.2, 1) 0s;
            color: white;
            .shadow();

            > h3 {
                font-size: 22px;
                margin-bottom: 8px;
                font-weight: bold;
                text-transform: uppercase;
                font-family: @main-font-family;
            }

            .gs-filtr-container {
                padding-block: 8px;
            }
        }

        &.show-side-filter {

            > #filter {
                transform: translateX(0);
            }

            .inner-wrapper:before {
                .appear();
            }
        }
    }
}

// RWD FILTER ONLY
@media (max-width: 1279px) {
    #filter {
        position: absolute;
        top: 0;
        left: 0;
        width: 100vw;
        height: 100%;
        z-index: 1001;
        background-color: white;
        overflow-y: auto;
        padding: 60px 10px 120px;

        .dropdown-picker {
            display: flex;
        }

        > h3 {
            display: none;
        }

        .gs-filtr-container {
            border-bottom: 1px solid @border-color;
            padding: 0;

            &:last-of-type {
                border-bottom: 0;
            }

            ul {
                padding-bottom: 13px;
                margin-top: -5px;
            }

            &:not(.colors-choice) ul {
                padding-left: 10px;
            }

            > input {
                font-size: 16px;
                margin-bottom: 15px;
                height: 40px;
            }

            > div,
            > input {
                display: none;
            }

            &.show-list {

                > div {
                    display: flex;

                    &#price-filter-amounts {
                        padding-bottom: 20px;
                    }
                }

                > input {
                    display: block;
                }

                > strong {

                    &:after {
                        transform: translateY(-50%) scaleY(-1);
                    }
                }
            }
        }

        strong {
            padding-block: 13px;
            cursor: pointer;
            font-weight: normal;

            &.any-selected {
                color: @main-color;
            }

            &:after {
                content: '\e800';
                font-family: icomoon;
                font-weight: normal;
                font-size: 10px;
                position: absolute;
                top: 50%;
                transform: translateY(-50%);
                right: 0;
            }

            span {
                display: block;
                color: @font-color;
                font-size: 12px;
                font-weight: normal;

                &:empty:before {
                    content: 'brak wybranych';
                }
            }
        }

        .dropdown-picker {
            flex-direction: column;
            width: 100%;
            padding-top: 20px;
            margin-bottom: -10px;
            border-radius: var(--borderRadius);

            .dropdown-listing {
                position: static;
                display: none;
                opacity: 1;
                visibility: visible;
                transform: none;
            }

            &.show-dropdown-rwd {

                .dropdown-listing {
                    display: block;
                }
            }

            &:after {
                content: 'Filtry';
                padding: 20px 0 0;
                position: static;
                font-size: 17px;
                color: @header-color;
                font-weight: bold;
                margin-bottom: 10px;
            }
        }
    }

    .close-filter {
        position: absolute;
        height: 60px;
        display: flex;
        align-items: center;
        color: @header-color;
        background-color: @border-color;
        top: 0;
        left: 0;
        width: 100%;
        font-weight: bold;
        text-transform: uppercase;
        font-size: 15px;
        cursor: pointer;
        user-select: none;
        gap: 20px;
        border-bottom: 1px solid rgba(0,0,0,.2);
        padding: 0 20px;

        &:before {
            content: '\e803';
            font-size: 12px;
            font-family: icomoon;
            font-weight: normal;
        }
    }

    .close-filter-bottom {
        display: flex;
        justify-content: center;
        position: fixed;
        bottom: 0;
        left: 0;
        width: 100%;
        height: 80px;
        align-items: center;
        background-color: rgba(255,255,255,.5);
        border-top: 1px solid @border-color;
        z-index: 4; // over .color-choice indexes

        > span {
            gap: 5px;
            font-size: 14px;

            &[data-count]:after {
                content: attr(data-count);
            }
        }
    }
}